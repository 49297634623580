import React, { useEffect, useRef } from "react";
import setInnerHTML from "../../../lib/setInnerHTML";
import { ComponentProps } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import * as styles from "./GoogleMap.module.css";

interface Props extends ComponentProps {
  mapCode: string;
}

export const GoogleMap: React.FC<Props> = ({
  mapCode,
  id,
  anchor,
  hideOnMobile,
}) => {
  const containerEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerEl?.current) {
      setInnerHTML(containerEl.current, mapCode);
    }
  }, [containerEl.current]);

  return (
    <SectionWrapper id={id} anchor={anchor} hideOnMobile={hideOnMobile}>
      <div
        className={styles.googlemap}
        data-kontent-element-codename="map_code"
        ref={containerEl}
      />
    </SectionWrapper>
  );
};
