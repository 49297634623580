import React, { useEffect, useRef, useState } from "react";
import * as styles from "./ExhibitionMap.module.css";
import { ComponentProps, Image, KontentRichText } from "../../../types";
import { KontentMapDetail } from ".";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { KontentImageElement } from "../../elements/KontentImageElement/KontentImageElement";
import { RichTextElement } from "@kontent-ai/gatsby-components";
import { MapDetail } from "./MapDetail";
import ResizeObserver from "resize-observer-polyfill";

interface Props extends ComponentProps {
  image?: Image;
  mapDetails?: KontentRichText & {
    modular_content: KontentMapDetail[];
  };
}

export const ExhibitionMap: React.FC<Props> = ({
  image,
  mapDetails,
  ...props
}) => {
  const imageRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [clientImageWidth, setClientImageWidth] = useState(0);
  const [contentRect, setContentRect] = useState(null as number | null);

  const observer = useRef(
    new ResizeObserver((entries) => {
      return setContentRect(entries[0].contentRect.width);
    })
  );

  useEffect(() => {
    if (imageRef.current) {
      const img = imageRef.current.querySelector("img");
      const clientWidth = contentRect || img.getBoundingClientRect().width;
      const imageWidth = image?.item.width;
      const scale = imageWidth && clientWidth / imageWidth;

      setClientImageWidth(clientWidth);
      scale && setScale(scale < 1 ? scale : 1);
      observer.current.observe(imageRef?.current);
    }

    return () => {
      if (imageRef?.current) {
        observer.current.unobserve(imageRef.current);
      }
    };
  }, [imageRef, observer, contentRect]);

  return (
    <SectionWrapper {...props}>
      <div className={styles.exhibitionmap}>
        {image && (
          <div ref={imageRef}>
            <KontentImageElement image={image.item} alt={image.alt} />
          </div>
        )}
        {mapDetails && (
          <RichTextElement
            value={mapDetails.value}
            links={mapDetails.links}
            linkedItems={mapDetails.modular_content}
            images={mapDetails.images}
            resolveLinkedItem={(mapDetail: KontentMapDetail) => {
              return (
                <MapDetail
                  {...mapDetail}
                  scale={scale}
                  clientImageWidth={clientImageWidth}
                />
              );
            }}
          />
        )}
      </div>
    </SectionWrapper>
  );
};
