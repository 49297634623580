import React from "react";
import { ComponentProps, ComponentWidth, Image } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import * as styles from "./VideoPlayer.module.css";
import ReactPlayer from "react-player";

interface Props extends ComponentProps, ComponentWidth {
  videoUrl: string;
}

export const VideoPlayer: React.FC<Props> = ({ videoUrl, width, ...props }) => {
  let containerClassName = styles.videoplayer;

  if (width === "contained") {
    containerClassName += ` ${styles.contained}`;
  }
  return (
    <SectionWrapper {...props}>
      <div className={containerClassName}>
        <div className={styles.playerWrapper}>
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="100%"
            className={styles.reactPlayer}
            controls
          />
        </div>
      </div>
    </SectionWrapper>
  );
};
