import React, { useState } from "react";
import { KontentRichText } from "../../../types";
import { CustomRichTextElement } from "../CustomRichTextElement/CustomRichTextElement";
import { ExpandCollapseButton } from "../ExpandCollapseButton/ExpandCollapseButton";
import * as styles from "./FAQ.module.css";

export interface FaqProps {
  label: string;
  contentCategory: string;
  text: KontentRichText;
}

export const Faq: React.FC<FaqProps> = ({ label, text, contentCategory }) => {
  const [expanded, setExpanded] = useState(false);

  const handleFaqClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles.faq}>
      <ExpandCollapseButton handleClick={handleFaqClick} open={expanded}>
        <h3>{label}</h3>
      </ExpandCollapseButton>

      <div
        className={
          expanded
            ? styles.textContainer + " " + styles.open
            : styles.textContainer
        }
      >
        <div className={styles.text}>
          <CustomRichTextElement content={text} />
        </div>
      </div>
    </div>
  );
};
