// extracted by mini-css-extract-plugin
export var arrow = "TimelineDetail-module--arrow--2c48b";
export var content = "TimelineDetail-module--content--918f0";
export var green = "TimelineDetail-module--green--2a4d7";
export var header = "TimelineDetail-module--header--4a1fd";
export var heading = "TimelineDetail-module--heading--01680";
export var image = "TimelineDetail-module--image--2995b";
export var large = "TimelineDetail-module--large--2466e";
export var low = "TimelineDetail-module--low--d9e90";
export var small = "TimelineDetail-module--small--1e5a1";
export var subhead = "TimelineDetail-module--subhead--5350a";
export var timelinedetail = "TimelineDetail-module--timelinedetail--2d668";