import React from "react";
import * as styles from "./ComponentCTA.module.css";
import { ComponentProps } from "../../../types/index";
import { Cta, LinkCtaProps } from "../../elements/Cta/Cta";
import { CTAStyles } from "./index";

interface Props extends ComponentProps {
  cta: LinkCtaProps;
  style: CTAStyles;
  leftAlign?: boolean;
}

export const ComponentCTA: React.FC<Props> = ({
  anchor,
  hideOnMobile,
  style,
  leftAlign,
  cta,
}) => {
  const classes = [styles.componentcta];
  leftAlign && classes.push(styles.left);
  hideOnMobile && classes.push("hide-mobile");

  return (
    <div id={anchor} className={classes.join(" ")}>
      <Cta {...cta} green={style === "green" ? true : false} />
    </div>
  );
};
