// extracted by mini-css-extract-plugin
export var caption = "MapDetail-module--caption--facca";
export var close = "MapDetail-module--close--03fb4";
export var floatLeft = "MapDetail-module--floatLeft--601a3";
export var link = "MapDetail-module--link--4fb2e";
export var mapdetail = "MapDetail-module--mapdetail--38005";
export var overlay = "MapDetail-module--overlay--cbd1a";
export var panel = "MapDetail-module--panel--d219d";
export var pin = "MapDetail-module--pin--7d913";
export var text = "MapDetail-module--text--e53d0";
export var title = "MapDetail-module--title--2d9fa";