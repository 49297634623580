import React from "react";
import { graphql } from "gatsby";
import { ExhibitionTimeline } from "./ExhibitionTimeline";
import {
  KontentBrandColor,
  KontentComponentSettings,
  KontentMultiChoice,
  KontentRichText,
  StringValue,
} from "../../../types";
import { KontentLinkedMediaImage } from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";
import { getColor } from "../../../lib/color";
import { formatImages } from "../../../lib/images";

export type HeaderTitleLocation = "high" | "low";
export type TimelineDetailSize = "small" | "medium" | "large";
export type HeaderTitleColor = "green" | "cream";
export interface KontentTimelineDetail {
  system?: {
    id?: string;
    type?: "element___timeline_detail";
    codename?: string;
  };
  internal?: {
    type: "kontent_item_element___timeline_detail";
  };
  elements: {
    header_image: KontentLinkedMediaImage;
    header_title: StringValue;
    header_title_location: KontentMultiChoice<HeaderTitleLocation>;
    header_title_color: KontentMultiChoice<HeaderTitleColor>;
    size: KontentMultiChoice<TimelineDetailSize>;
    detail_heading: StringValue;
    detail_subhead: StringValue;
    text: KontentRichText;
  };
}

interface Props extends KontentComponentSettings {
  header_background_color: {
    value: KontentBrandColor[];
  };
  image: KontentLinkedMediaImage;
  timeline_details: KontentRichText & {
    modular_content: KontentTimelineDetail[];
  };
}

const ExhibitionTimelineContainer: React.FC<Props> = ({
  header_background_color,
  image,
  timeline_details,
  id,
  component_settings__anchor_name,
  component_settings__hide_on_mobile,
}) => {
  return (
    <ExhibitionTimeline
      id={id}
      anchor={component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
      backgroundColor={getColor(header_background_color)}
      timelineDetails={timeline_details}
      image={formatImages(image.value[0]?.elements)}
    ></ExhibitionTimeline>
  );
};

export default ExhibitionTimelineContainer;

export const fragmentExhibitionTimelineContainer = graphql`
  fragment ExhibitionTimeline on kontent_item_component___exhibition_timeline {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      header_background_color {
        ...brandColor
      }
      image {
        value {
          ...kontentMediaImage
        }
      }
      timeline_details {
        value
        modular_content {
          ... on kontent_item_element___timeline_detail {
            system {
              id
              type
              codename
            }
            internal {
              type
            }
            elements {
              header_image {
                value {
                  ...kontentMediaImage
                }
              }
              header_title {
                value
              }
              header_title_location {
                value {
                  codename
                }
              }
              header_title_color {
                value {
                  codename
                }
              }
              size {
                value {
                  codename
                }
              }
              detail_heading {
                value
              }
              detail_subhead {
                value
              }
              text {
                ...kontentSimpleRichText
              }
            }
          }
        }
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
    }
  }
`;
