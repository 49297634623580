import React from "react";
import { graphql } from "gatsby";
import { ExhibitionMap } from "./ExhibitionMap";
import {
  KontentComponentSettings,
  KontentLinkedMediaImage,
  KontentLinks,
  KontentRichText,
  StringValue,
} from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";
import { formatImages } from "../../../lib/images";

export interface KontentMapDetail {
  system?: {
    id?: string;
    type?: "element___map_detail";
    codename?: string;
  };
  internal?: {
    type: "kontent_item_element___map_detail";
  };
  elements: {
    x_coordinate: {
      value: number;
    };
    y_coordinate: {
      value: number;
    };
    title: StringValue;
    text: KontentRichText;
    link_configuration__link: KontentLinks;
    image: KontentLinkedMediaImage;
  };
}
interface Props extends KontentComponentSettings {
  image: KontentLinkedMediaImage;
  map_detail: KontentRichText & {
    modular_content: KontentMapDetail[];
  };
}

const ExhibitionMapContainer: React.FC<Props> = ({ ...props }) => {
  return (
    <ExhibitionMap
      id={props.id}
      anchor={props.component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(props.component_settings__hide_on_mobile) === "yes"
      }
      image={formatImages(props.image.value[0]?.elements).largeImage}
      mapDetails={props.map_detail}
    ></ExhibitionMap>
  );
};

export default ExhibitionMapContainer;

export const fragmentExhibitionMapContainer = graphql`
  fragment ExhibitionMap on kontent_item_component___exhibition_map {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      image {
        value {
          ...kontentMediaImage
        }
      }
      map_detail {
        ...kontentSimpleRichText
        modular_content {
          ... on kontent_item_element___map_detail {
            id
            system {
              id
              codename
              type
            }
            internal {
              type
            }
            elements {
              x_coordinate {
                value
              }
              y_coordinate {
                value
              }
              title {
                value
              }
              text {
                ...kontentSimpleRichText
              }
              link_configuration__link {
                modular_content {
                  ...kontentRichTextLink
                }
              }
              image {
                value {
                  ...kontentMediaImage
                }
              }
            }
          }
        }
      }
    }
  }
`;
