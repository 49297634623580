import React, { useState } from "react";
import * as styles from "./MapDetail.module.css";
import { KontentMapDetail } from ".";
import { formatImages } from "../../../lib/images";
import { KontentImageElement } from "../../elements/KontentImageElement/KontentImageElement";
import { CustomRichTextElement } from "../../elements/CustomRichTextElement/CustomRichTextElement";
import pin from "./assets/pin.png";
import { formatLinks } from "../../../lib/links";
import { Link } from "../../elements/Link/Link";
import CloseButton from "../../elements/CloseButton/CloseButton";
import { brandColors } from "../../../lib/color";

interface Props extends KontentMapDetail {
  scale?: number;
  clientImageWidth: number;
}

export const MapDetail: React.FC<Props> = ({
  system,
  elements,
  scale = 1,
  clientImageWidth,
  ...props
}) => {
  const { x_coordinate, y_coordinate, title, text, link_configuration__link } =
    elements;
  const image = formatImages(elements.image?.value[0]?.elements)?.largeImage;
  const link = formatLinks(link_configuration__link?.modular_content)[0];
  const [open, setOpen] = useState(false);
  const topPosition = y_coordinate.value * scale;
  const leftPosition = x_coordinate.value * scale;
  const position = {
    top: `${topPosition}px`,
    left: `${leftPosition}px`,
    "--left-position": leftPosition,
  };
  const panelStyles = [styles.panel];
  const willOverflowWidth = (leftPosition + 305) * scale > clientImageWidth;
  willOverflowWidth && panelStyles.push(styles.floatLeft);

  const toggleDetail = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.mapdetail}>
      <button
        className={styles.pin}
        style={{ ...position, zIndex: open ? 2 : 0 }}
        onClick={toggleDetail}
      >
        <img src={pin} alt="pin" />
      </button>
      {open && (
        <>
          <div
            className={styles.overlay}
            style={{ maxWidth: clientImageWidth }}
            onClick={toggleDetail}
          ></div>
          <div className={panelStyles.join(" ")} style={position}>
            <div className={styles.close}>
              <CloseButton
                color={brandColors.black}
                onClick={toggleDetail}
                size={16}
              />
            </div>
            <div className={styles.title}>{title.value}</div>
            {image?.item && (
              <div>
                <KontentImageElement
                  image={image.item}
                  alt={image.alt}
                  width={image.item.width < 1500 ? image.item.width : 1500}
                />
                {image.caption && image.caption?.value !== "<p><br></p>" && (
                  <div className={styles.caption}>
                    <CustomRichTextElement content={image.caption} />
                  </div>
                )}
              </div>
            )}
            {text && text.value !== "<p><br></p>" && (
              <div className={styles.text}>
                <CustomRichTextElement content={text} />
              </div>
            )}
            {link && link.href && (
              <div className={styles.link}>
                <Link to={link.href} target={link.target}>
                  {link.label}
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
