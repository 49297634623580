import React, { useState, useEffect, useRef, useCallback } from "react";
import * as styles from "./ExhibitionTimeline.module.css";
import { KontentTimelineDetail } from ".";
import { ComponentProps, Image, KontentRichText } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { RichTextElement } from "@kontent-ai/gatsby-components";
import { TimelineDetail } from "./TimelineDetail";
import ResizeObserver from "resize-observer-polyfill";
import arrows from "./assets/arrows.png";

interface Props extends ComponentProps {
  backgroundColor?: string;
  image?: {
    largeImage: Image;
    smallImage?: Image;
  };
  timelineDetails: KontentRichText & {
    modular_content: KontentTimelineDetail[];
  };
}

export const ExhibitionTimeline: React.FC<Props> = ({
  backgroundColor,
  image,
  timelineDetails,
  ...props
}) => {
  if (typeof window === undefined) return null;

  const [componentHeight, setComponentHeight] = useState(null);
  const [componentMaxWidth, setComponentMaxWidth] = useState(null);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [showArrows, setShowArrows] = useState(true);
  const [isDragging, setIsDragging] = useState<boolean | null>(null);
  const [width, setWidth] = useState(
    null as ResizeObserverEntry["contentRect"] | null
  );
  const [dragIndicatorPosition, setDragIndicatorPosition] = useState(0);
  const [timelineScrollPosition, setTimelineScrollPosition] = useState(0);
  const [scrollChange, setScrollChange] = useState(0);

  const timelineContainer = useRef(null);
  const detailContainer = useRef<HTMLElement>(null);
  const dragIndicator = useRef(null);
  const timerId = useRef<any>(null);

  const observer = useRef(
    new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect);
    })
  );

  const calcDragIndicatorPosition = useCallback(
    (clientX?: number, initialX?: number) => {
      if (timelineContainer.current && detailContainer.current) {
        const componentWidth =
          timelineContainer.current.getBoundingClientRect().width;

        let newPosition =
          clientX && initialX
            ? dragIndicatorPosition + clientX - initialX
            : componentWidth *
              (timelineScrollPosition / detailContainer.current.scrollWidth);

        if (newPosition < 0) {
          newPosition = 0;
        }

        if (newPosition > componentWidth - indicatorWidth) {
          newPosition = componentWidth - indicatorWidth;
        }

        return newPosition;
      }
    },
    [
      componentMaxWidth,
      dragIndicatorPosition,
      indicatorWidth,
      width,
      timelineScrollPosition,
    ]
  );

  const handleIndicatorTouch = (event: React.TouchEvent) => {
    const initialX = event.touches[0]?.clientX;

    const moveCallback = (ev: TouchEvent) => {
      if (detailContainer.current) {
        const newDragIndicatorPosition = calcDragIndicatorPosition(
          ev.touches[0].clientX,
          initialX
        );

        if (newDragIndicatorPosition !== undefined) {
          let newTimelineScrollPosition =
            newDragIndicatorPosition === 0
              ? 0
              : (newDragIndicatorPosition /
                  (componentMaxWidth - indicatorWidth)) *
                  detailContainer.current.scrollWidth -
                indicatorWidth;

          if (newTimelineScrollPosition < 0) {
            newTimelineScrollPosition = 0;
          }

          if (
            newTimelineScrollPosition >
            detailContainer.current.scrollWidth - componentMaxWidth
          ) {
            newTimelineScrollPosition =
              detailContainer.current.scrollWidth - componentMaxWidth;
          }

          setDragIndicatorPosition(newDragIndicatorPosition);
          setTimelineScrollPosition(newTimelineScrollPosition);
        }
      }
    };

    window?.addEventListener("touchmove", moveCallback);

    window?.addEventListener("touchend", () => {
      window?.removeEventListener("touchmove", moveCallback);
    });
  };

  const handleIndicatorDrag = (event: React.MouseEvent) => {
    const initialX = event.clientX;
    event.preventDefault();
    setIsDragging(true);

    const moveCallback = (ev: MouseEvent) => {
      if (detailContainer.current) {
        const newDragIndicatorPosition = calcDragIndicatorPosition(
          ev.clientX,
          initialX || 0
        );

        if (newDragIndicatorPosition !== undefined) {
          let newTimelineScrollPosition =
            newDragIndicatorPosition === 0
              ? 0
              : (newDragIndicatorPosition /
                  (componentMaxWidth - indicatorWidth)) *
                  detailContainer.current.scrollWidth -
                indicatorWidth;

          if (newTimelineScrollPosition < 0) {
            newTimelineScrollPosition = 0;
          }

          if (
            newTimelineScrollPosition >
            detailContainer.current.scrollWidth - componentMaxWidth
          ) {
            newTimelineScrollPosition =
              detailContainer.current.scrollWidth - componentMaxWidth;
          }

          setDragIndicatorPosition(newDragIndicatorPosition);
          setTimelineScrollPosition(newTimelineScrollPosition);
        }
      }
    };

    window?.addEventListener("mousemove", moveCallback);

    window?.addEventListener("mouseup", () => {
      window?.removeEventListener("mousemove", moveCallback);
      setIsDragging(false);
    });
  };

  useEffect(() => {
    if (isDragging) return;

    const newDragIndicatorPosition = calcDragIndicatorPosition();

    if (
      newDragIndicatorPosition &&
      newDragIndicatorPosition !== dragIndicatorPosition
    ) {
      setDragIndicatorPosition(newDragIndicatorPosition);
    }
  }, [timelineScrollPosition]);

  useEffect(() => {
    let newTimelineScrollPosition = timelineScrollPosition + scrollChange;

    if (newTimelineScrollPosition < 0) {
      newTimelineScrollPosition = 0;
    }
    if (
      newTimelineScrollPosition >
      detailContainer.current.scrollWidth - componentMaxWidth
    ) {
      newTimelineScrollPosition =
        detailContainer.current.scrollWidth - componentMaxWidth;
    }
    setTimelineScrollPosition(newTimelineScrollPosition);
  }, [scrollChange]);

  useEffect(() => {
    let initialTouchY: number;

    const handleDetailContainerWheel = (event: WheelEvent) => {
      if (isDragging) return;
      setScrollChange(event.deltaY);
    };

    const handleDetailContainerTouchmove = (event: TouchEvent) => {
      if (isDragging) return;
      event.preventDefault();
      document.body.style.setProperty("overflow", "hidden");
      const newY = event.changedTouches[0].clientY;
      const deltaY = newY - initialTouchY;
      initialTouchY = newY;
      setScrollChange(deltaY);
    };

    const handleDetailContainerTouchend = () => {
      if (detailContainer.current && !isDragging) {
        timerId.current && clearTimeout(timerId.current);
        timerId.current = setTimeout(
          () => document.body.style.removeProperty("overflow"),
          400
        );

        detailContainer.current.removeEventListener(
          "touchend",
          handleDetailContainerTouchend
        );
      }
    };

    const handleDetailContainerTouchstart = (event: TouchEvent) => {
      if (isDragging) return;

      if (detailContainer.current) {
        event.preventDefault();
        document.body.style.setProperty("overflow", "hidden");
        initialTouchY = event.touches[0].clientY;

        detailContainer.current.addEventListener(
          "touchmove",
          handleDetailContainerTouchmove
        );

        detailContainer.current.addEventListener(
          "touchend",
          handleDetailContainerTouchend
        );
      }
    };

    const handleDetailContainerMouseLeave = () => {
      if (isDragging) return;
      window.removeEventListener("wheel", handleDetailContainerWheel);
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        document.body.style.removeProperty("overflow");
      }, 400);
    };

    const handleDetailContainerMouseEnter = () => {
      if (isDragging) return;

      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        document.body.style.setProperty("overflow", "hidden");
      }, 400);
      window.addEventListener("wheel", handleDetailContainerWheel);

      if (detailContainer.current) {
        detailContainer.current.addEventListener(
          "mouseleave",
          handleDetailContainerMouseLeave
        );
      }
    };

    if (detailContainer.current) {
      detailContainer.current.addEventListener(
        "mouseenter",
        handleDetailContainerMouseEnter
      );
      detailContainer.current.addEventListener(
        "touchstart",
        handleDetailContainerTouchstart
      );
    }

    return () => {
      if (detailContainer.current) {
        document.body.style.removeProperty("overflow");
        detailContainer.current.removeEventListener(
          "mouseenter",
          handleDetailContainerMouseEnter
        );
        detailContainer.current.addEventListener(
          "mouseleave",
          handleDetailContainerMouseLeave
        );
        window.removeEventListener("wheel", handleDetailContainerWheel);
      }
    };
  }, [detailContainer.current]);

  useEffect(() => {
    if (dragIndicator?.current) {
      setIndicatorWidth(dragIndicator.current.getBoundingClientRect().width);
    }

    if (detailContainer?.current && timelineContainer?.current) {
      const height = detailContainer?.current?.getBoundingClientRect().height;
      const scrollWidth = detailContainer?.current?.scrollWidth;
      const elemWidth =
        timelineContainer?.current?.getBoundingClientRect().width;

      setComponentMaxWidth(elemWidth);
      setShowArrows(scrollWidth > elemWidth);
      setComponentHeight(`${height}px`);
    }

    if (timelineContainer.current) {
      observer.current.observe(timelineContainer?.current);
    }

    return () => {
      if (timelineContainer.current) {
        observer.current.unobserve(timelineContainer?.current);
      }
    };
  }, [detailContainer, timelineContainer, dragIndicator]);

  const backgroundVars = {
    "--header-large-background": image?.largeImage?.item?.url
      ? `url(${image?.largeImage?.item?.url})`
      : undefined,
    "--header-small-background": image?.smallImage?.item?.url
      ? `url(${image?.smallImage?.item?.url})`
      : undefined,
    backgroundColor: backgroundColor,
  };

  return (
    <SectionWrapper {...props}>
      <div
        className={styles.exhibitiontimeline}
        style={{ minHeight: componentHeight }}
        ref={timelineContainer}
      >
        <div className={styles.header} style={backgroundVars}></div>
        {showArrows && (
          <>
            <div
              className={styles.dragIndicator}
              style={{
                left: `${dragIndicatorPosition}px`,
              }}
              ref={dragIndicator}
              onMouseDown={handleIndicatorDrag}
              onTouchStart={handleIndicatorTouch}
            >
              <img src={arrows} alt={"Touch to scroll"} />{" "}
            </div>
          </>
        )}
        <div
          className={styles.details}
          ref={detailContainer}
          style={{
            maxWidth: `${componentMaxWidth}px`,
            left:
              timelineScrollPosition === 0
                ? `0px`
                : `-${timelineScrollPosition}px`,
          }}
        >
          <RichTextElement
            value={timelineDetails?.value}
            links={timelineDetails?.links}
            linkedItems={timelineDetails?.modular_content}
            images={timelineDetails?.images}
            resolveLinkedItem={(timelineDetail: KontentTimelineDetail) => {
              return <TimelineDetail {...timelineDetail} />;
            }}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};
