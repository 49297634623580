import React, { useEffect, useRef } from "react";
import * as styles from "./SplashEmbed.module.css";
import { ComponentProps, ComponentWidth } from "../../../types/index";
import { SectionWrapper } from "../../containers/SectionWrapper";
import setInnerHTML from "../../../lib/setInnerHTML";

interface Props extends ComponentWidth, ComponentProps {
  embedCode: string;
}

export const SplashEmbed: React.FC<Props> = ({
  width = "contained",
  embedCode,
  id,
  anchor,
  hideOnMobile,
}) => {
  const containerEl = useRef<HTMLDivElement>(null);

  let sectionClassName = styles.splashembed;
  if (width === "contained") {
    sectionClassName += ` ${styles.contained}`;
  }

  useEffect(() => {
    function renderCodeDelayed() {
      setTimeout(renderCode, 500);
    }

    function renderCode() {
      if (containerEl?.current) {
        if ("requestIdleCallback" in window) {
          requestIdleCallback(function () {
            setInnerHTML(containerEl.current, embedCode);
          });
        } else {
          setInnerHTML(containerEl.current, embedCode);
        }
      }
    }

    /* delay script placement until after user scroll to fix time to render */
    window.addEventListener(
      "scroll",
      function () {
        renderCodeDelayed();
      },
      { once: true }
    );
  }, []);

  return embedCode ? (
    <SectionWrapper id={id} anchor={anchor} hideOnMobile={hideOnMobile}>
      <div
        className={sectionClassName}
        ref={containerEl}
        data-kontent-element-codename="embed_code"
      ></div>
    </SectionWrapper>
  ) : (
    <p>Missing Splash Embed Code</p>
  );
};
