import React from "react";
import { graphql } from "gatsby";
import { LinkedImage } from "./LinkedImage";
import {
  KontentComponentSettings,
  KontentLinkedMediaImage,
  KontentLinks,
} from "../../../types";
import { formatLinks } from "../../../lib/links";
import { getMultiChoice } from "../../../lib/multichoice";

interface Props extends KontentComponentSettings {
  link_configuration__link: KontentLinks;
  image: KontentLinkedMediaImage;
}

const LinkedImageContainer: React.FC<Props> = ({
  id,
  link_configuration__link,
  image,
}) => {
  const link = formatLinks(link_configuration__link.modular_content)[0];
  return <LinkedImage id={id} link={link} image={image}></LinkedImage>;
};

export default LinkedImageContainer;

export const fragmentLinkedImage = graphql`
  fragment LinkedImage on kontent_item_component___linked_image {
    elements {
      link_configuration__link {
        modular_content {
          ...kontentRichTextLink
        }
      }
      image {
        modular_content {
          ...kontentMediaImage
        }
      }
    }
  }
`;
