import React from "react";
import { graphql } from "gatsby";
import { VideoPlayer } from "./VideoPlayer";
import {
  KontentComponentLayout,
  KontentComponentSettings,
  KontentLinkedMediaVideo,
} from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";
import { formatImages } from "../../../lib/images";
import { JsonLd } from "gatsby-plugin-next-seo";
import { VideoObject } from "gatsby-plugin-next-seo/node_modules/schema-dts";

interface Props extends KontentComponentSettings, KontentComponentLayout {
  video: KontentLinkedMediaVideo;
}

const VideoPlayerContainer: React.FC<Props> = ({
  video,
  component_settings__anchor_name,
  component_layout__component_width,
  component_settings__hide_on_mobile,
  id,
}) => {
  const { video_url, description, name, thumbnail, upload_date } =
    video?.value[0]?.elements;

  return (
    <>
      <JsonLd<VideoObject>
        json={{
          "@context": "https://schema.org",
          "@type": "VideoObject",
          name: name?.value,
          description: description?.value,
          contentUrl: video_url.value,
          thumbnailUrl: formatImages(thumbnail.value[0]?.elements).largeImage
            ?.item?.url,
          uploadDate: upload_date?.value,
        }}
      />
      <VideoPlayer
        id={id}
        width={getMultiChoice(component_layout__component_width)}
        anchor={component_settings__anchor_name.value}
        hideOnMobile={
          getMultiChoice(component_settings__hide_on_mobile) === "yes"
        }
        videoUrl={video_url.value}
      ></VideoPlayer>
    </>
  );
};

export default VideoPlayerContainer;

export const fragmentVideoPlayerContainer = graphql`
  fragment VideoPlayer on kontent_item_component___video_player {
    elements {
      video {
        value {
          ... on kontent_item_media___video {
            id
            elements {
              description {
                value
              }
              video_url {
                value
              }
              name {
                value
              }
              upload_date {
                value
              }
              thumbnail {
                value {
                  ...kontentMediaImage
                }
              }
            }
          }
        }
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      component_settings__anchor_name {
        value
      }
      component_layout__component_width {
        value {
          codename
        }
      }
    }
  }
`;
