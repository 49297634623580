import React from "react";
import {
  ComponentProps,
  ComponentWidth,
  KontentRichText,
  Image,
} from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { CustomRichTextElement } from "../../elements/CustomRichTextElement/CustomRichTextElement";
import { KontentImageElement } from "../../elements/KontentImageElement/KontentImageElement";
import * as styles from "./BlockQuote.module.css";

interface Props extends ComponentProps, ComponentWidth {
  attributionName: string;
  attributionTitle: string;
  image: { largeImage?: Image };
  quoteContent: string;
  additionalByline: KontentRichText;
}

export const BlockQuote: React.FC<Props> = ({
  quoteContent,
  attributionName,
  attributionTitle,
  image,
  additionalByline,
  width,
  ...props
}) => {
  return (
    <SectionWrapper {...props}>
      <div className={styles.blockquote}>
        <div className={styles.textContainer}>
          <p
            className={styles.quoteContent}
            data-kontent-element-codename="quote_content"
          >
            {quoteContent}
          </p>
          {(attributionName || additionalByline) && (
            <div
              className={styles.attribution}
              data-kontent-element-codename="attribution_name"
            >
              <strong>
                - {attributionName}
                {attributionTitle && ", " + attributionTitle}
              </strong>
              <CustomRichTextElement
                content={additionalByline}
                data-kontent-element-codename="additional_byline"
              />
            </div>
          )}
        </div>
        {image?.largeImage?.item && (
          <div
            className={styles.imageContainer}
            data-kontent-element-codename="image"
          >
            <KontentImageElement
              image={image?.largeImage?.item}
              alt={image.largeImage.alt}
              layout="constrained"
              height={515}
              width={515}
            />
          </div>
        )}
      </div>
    </SectionWrapper>
  );
};
