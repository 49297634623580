import React from "react";
import { ComponentProps } from "../../types";

interface Props extends ComponentProps {
  id: string;
  background?: string;
  domElement?: string;
}

export const SectionWrapper: React.FC<Props> = ({
  anchor,
  hideOnMobile,
  id,
  background,
  children,
  domElement = "section",
}) => {
  const DomElement = domElement as keyof JSX.IntrinsicElements;
  const styles: React.CSSProperties = {};
  if (background) {
    styles.background = background;
  }

  return (
    <DomElement
      data-kontent-component-id={id}
      id={anchor !== "" ? anchor : undefined}
      className={hideOnMobile ? "hide-mobile" : undefined}
      style={styles}
    >
      {children}
    </DomElement>
  );
};
