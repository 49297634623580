import { RichTextElement } from "@kontent-ai/gatsby-components";
import React from "react";
import { KontentLinkWithSublinks } from ".";
import { formatLinks } from "../../../lib/links";
import { KontentLink, KontentRichText } from "../../../types";
import { LinkWithSublinks } from "./LinkWithSublink";
import * as styles from "./SidebarLinks.module.css";

interface Props {
  links: {
    value: string;
    modular_content: (KontentLink | KontentLinkWithSublinks)[];
  };
}

export const SidebarLinks: React.FC<Props> = ({ links }) => {
  return (
    <div className={styles.sidebarLinks}>
      <RichTextElement
        value={links.value}
        linkedItems={links.modular_content}
        resolveLinkedItem={(item: KontentLink | KontentLinkWithSublinks) => {
          let link;
          switch (item.internal?.type) {
            case "kontent_item_element___link":
              link = formatLinks([item as KontentLink])[0];
              return (
                <a
                  className={styles.sidebarLink}
                  href={link.href}
                  target={link.target}
                >
                  {link.label}
                </a>
              );
            case "kontent_item_element___link_with_sublinks":
              return (
                <LinkWithSublinks
                  primaryLink={
                    formatLinks(
                      //@ts-ignore
                      item.elements.primary_link?.modular_content
                    )[0]
                  }
                  sublinks={formatLinks(
                    //@ts-ignore
                    item.elements.sublinks.modular_content
                  )}
                />
              );
            default:
              return;
          }
        }}
      />
    </div>
  );
};
