import React from "react";
import { graphql } from "gatsby";
import { ComponentCTA } from "./ComponentCTA";
import {
  KontentComponentSettings,
  KontentLinks,
  KontentMultiChoice,
} from "../../../types/index";
import { getMultiChoice } from "../../../lib/multichoice";
import { formatLinks } from "../../../lib/links";

export type CTAStyles = "white" | "green";

interface Props extends KontentComponentSettings {
  link_configuration__link: KontentLinks;
  style: KontentMultiChoice<CTAStyles>;
  leftAlign?: boolean;
}

const ComponentCTAContainer: React.FC<Props> = (props) => {
  const cta = formatLinks(props?.link_configuration__link?.modular_content)[0];
  const style = getMultiChoice(props?.style);

  return (
    <ComponentCTA
      id={props?.id}
      anchor={props?.component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(props?.component_settings__hide_on_mobile) === "yes"
      }
      style={style}
      cta={cta}
      leftAlign={props?.leftAlign}
    />
  );
};

export default ComponentCTAContainer;

export const fragmentCTAContainer = graphql`
  fragment ComponentCTA on kontent_item_component___cta {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      style {
        value {
          codename
        }
      }
      link_configuration__link {
        modular_content {
          ...kontentRichTextLink
        }
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
    }
  }
`;
