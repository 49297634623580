import React, { useContext, useState } from "react";
import { PageMetadataContext } from "../../../lib/PageMetadataContext";
import { PageLink } from "../../../types";
import { ExpandCollapseButton } from "../../elements/ExpandCollapseButton/ExpandCollapseButton";
import * as styles from "./SidebarLinks.module.css";

interface Props {
  primaryLink: PageLink;
  sublinks: PageLink[];
}

export const LinkWithSublinks: React.FC<Props> = ({
  primaryLink,
  sublinks,
}) => {
  const location = useContext(PageMetadataContext).location;
  const onSubpage =
    primaryLink.href === location?.pathname ||
    sublinks.filter((link) => link.href === location?.pathname).length > 0;
  const [sublinksOpen, setSublinksOpen] = useState(onSubpage);

  const handleExpandCollapseClick = () => {
    setSublinksOpen(!sublinksOpen);
  };

  return (
    <>
      <div
        className={`${styles.sidebarLink} ${styles.expandable} ${
          sublinksOpen ? styles.open : ""
        }`}
      >
        <a href={primaryLink.href} target={primaryLink.target}>
          {primaryLink.label}
        </a>
        <ExpandCollapseButton
          handleClick={handleExpandCollapseClick}
          open={sublinksOpen}
          children={""}
        ></ExpandCollapseButton>
      </div>
      {sublinksOpen &&
        sublinks.map((sublink, idx) => (
          <a
            className={
              styles.sidebarLink + " " + styles.open + " " + styles.sublink
            }
            href={sublink.href}
            target={sublink.target}
            key={`link.href${idx}`}
          >
            {sublink.label}
          </a>
        ))}
    </>
  );
};
