import React, { Component } from "react";
import { graphql } from "gatsby";
import { IconGroup } from "./IconGroup";
import {
  KontentComponentSettings,
  KontentLinkedMediaIcon,
  StringValue,
  KontentLinks,
  KontentMultiChoice,
  KontentLinkedMediaImage,
  KontentMediaIcon,
} from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";
import { formatIcon, formatImages } from "../../../lib/images";
import { formatLinks } from "../../../lib/links";

interface KontentIconLink {
  id: string;
  elements: {
    icon: KontentLinkedMediaIcon | KontentLinkedMediaImage;
    link_configuration__link: KontentLinks;
    text: StringValue;
    title: StringValue;
    icon_width: KontentMultiChoice<"full_width" | "small">;
  };
}

interface Props extends KontentComponentSettings {
  icon_links: { value: KontentIconLink[] };
}

const IconGroupContainer: React.FC<Props> = ({
  id,
  icon_links,
  component_settings__anchor_name,
  component_settings__hide_on_mobile,
  ...props
}) => {
  const formatIconLinks = () => {
    return icon_links.value.map((iconLink) => {
      const elements = iconLink.elements;
      const image = elements?.icon?.value[0]?.elements["icon"]
        ? formatIcon(elements?.icon?.value[0]?.elements)
        : formatImages(elements?.icon?.value[0]?.elements).largeImage;
      const link = formatLinks(
        elements.link_configuration__link?.modular_content
      )[0];
      const makeFullWidth =
        getMultiChoice(elements.icon_width) === "full_width" ? true : false;

      return {
        icon: image,
        link: link,
        text: elements.text.value,
        title: elements.title.value,
        makeFullWidth: makeFullWidth,
      };
    });
  };

  const iconLinks = formatIconLinks();
  return (
    <IconGroup
      id={id}
      iconLinks={iconLinks}
      anchor={component_settings__anchor_name.value}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
    ></IconGroup>
  );
};

export default IconGroupContainer;

export const fragmentIconGroup = graphql`
  fragment IconGroup on kontent_item_component___icon_group {
    elements {
      icon_links {
        value {
          ... on kontent_item_element___icon_link {
            id
            elements {
              icon {
                value {
                  ...kontentMediaImage
                  ... on kontent_item_media___icon {
                    id
                    elements {
                      icon {
                        value {
                          gatsbyImageData
                          description
                          url
                        }
                      }
                      alt_text {
                        value
                      }
                    }
                  }
                }
              }
              link_configuration__link {
                modular_content {
                  ...kontentRichTextLink
                }
              }
              text {
                value
              }
              title {
                value
              }
              icon_width {
                value {
                  codename
                }
              }
            }
          }
        }
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
    }
  }
`;
