import React from "react";
import { graphql } from "gatsby";
import { HorizontalRule } from "./HorizontalRule";
import { getColor } from "../../../lib/color";
import { KontentBrandColor } from "../../../types/index";

interface Props {
  color: {
    value: KontentBrandColor[];
  };
}

const HorizontalRuleContainer: React.FC<Props> = (props) => {
  return <HorizontalRule color={getColor(props.color)} />;
};

export default HorizontalRuleContainer;

export const fragmentComponentHorizontalRuleContainer = graphql`
  fragment HorizontalRule on kontent_item_component___horizontal_rule {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      color {
        ...brandColor
      }
    }
  }
`;
