import React from "react";
import { graphql } from "gatsby";
import { FAQs } from "./FAQs";
import {
  KontentComponentSettings,
  KontentRichText,
  StringValue,
} from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";

type KontentFaq = {
  elements: {
    content_category: {
      value: {
        name?: string;
        codename?: string;
      };
    };
    label: StringValue;
    long_rte__long_text: KontentRichText;
  };
};

interface Props extends KontentComponentSettings {
  faqs: KontentRichText;
  subhead: StringValue;
}

const FAQsContainer: React.FC<Props> = ({
  id,
  component_settings__hide_on_mobile,
  component_settings__anchor_name,
  faqs,
  subhead,
}) => {
  const formatFaq = (faq: KontentFaq) => {
    const faqElements = faq.elements;

    return {
      label: faqElements.label?.value,
      text: faqElements.long_rte__long_text,
      contentCategory: faqElements.content_category?.value[0]?.name,
    };
  };

  const formattedFaqs = faqs?.modular_content?.map((faq) => formatFaq(faq));

  return (
    <FAQs
      faqs={formattedFaqs}
      subhead={subhead?.value}
      id={id}
      anchor={component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
    />
  );
};

export default FAQsContainer;

export const fragmentFAQsContainer = graphql`
  fragment FAQs on kontent_item_component___faqs {
    elements {
      subhead {
        value
      }
      faqs {
        modular_content {
          ... on kontent_item_element___faq {
            id
            elements {
              content_category {
                value {
                  name
                }
              }
              label {
                value
              }
              long_rte__long_text {
                ...kontentSimpleRichText
              }
            }
          }
        }
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      component_settings__anchor_name {
        value
      }
    }
  }
`;
