import React from "react";
import * as styles from "./ExpandCollapseButton.module.css";

interface Props {
  handleClick: () => void;
  open: boolean;
  children: React.ReactChild;
}

export const ExpandCollapseButton: React.FC<Props> = ({
  handleClick,
  open,
  children,
  ...props
}) => {
  return (
    <button
      onClick={handleClick}
      className={styles.expandCollapseButton}
      type="button"
    >
      {open && <span>-</span>}
      {!open && <span>+</span>}
      {children}
    </button>
  );
};
