import React from "react";
import { graphql } from "gatsby";
import { SplashEmbed } from "./SplashEmbed";
import {
  KontentComponentSettings,
  KontentMultiChoice,
  StringValue,
} from "../../../types/index";
import { getMultiChoice } from "../../../lib/multichoice";

interface Props extends KontentComponentSettings {
  embed_code: StringValue;
  component_layout__component_width: KontentMultiChoice<"full" | "contained">;
}

const SplashEmbedContainer: React.FC<Props> = ({
  embed_code,
  component_layout__component_width,
  ...props
}) => {
  const componentWidth = getMultiChoice(component_layout__component_width);

  return (
    <SplashEmbed
      embedCode={embed_code?.value}
      width={componentWidth}
      id={props.id}
      anchor={props.component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(props.component_settings__hide_on_mobile) === "yes"
      }
    />
  );
};

export default SplashEmbedContainer;

export const fragmentSplashEmbedContainer = graphql`
  fragment SplashEmbedContainer on kontent_item_component___splash_embed {
    elements {
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      component_layout__component_width {
        value {
          codename
        }
      }
      embed_code {
        value
      }
    }
  }
`;
