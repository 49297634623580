import React from "react";
import * as styles from "./HorizontalRule.module.css";

interface Props {
  color: string | undefined;
}

export const HorizontalRule: React.FC<Props> = ({ color }) => {
  let style: React.CSSProperties = {};

  if (color) {
    style.borderColor = color;
  }
  return <hr className={styles.horizontalrule} style={style} />;
};
