/* create and replace scripts otherwise they won't run when set via innerHTML */
const setInnerHTML = (el: HTMLElement, html: string) => {
  el.innerHTML = html;
  Array.from(el.querySelectorAll("script")).forEach((oldScript) => {
    const newScript = document.createElement("script");
    Array.from(oldScript.attributes).forEach((attr) =>
      newScript.setAttribute(attr.name, attr.value)
    );
    newScript.setAttribute("defer", "defer");
    newScript.appendChild(document.createTextNode(oldScript.innerHTML));
    oldScript?.parentNode?.replaceChild(newScript, oldScript);
  });
};

export default setInnerHTML;
