import React from "react";
import { ComponentProps } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import * as styles from "./FAQs.module.css";
import { Faq, FaqProps } from "../../elements/FAQ/FAQ";

interface Props extends ComponentProps {
  faqs?: FaqProps[];
  subhead: string;
}

export const FAQs: React.FC<Props> = ({ subhead, faqs, ...props }) => {
  return (
    <SectionWrapper {...props}>
      <div className={styles.faqs}>
        <h2>{subhead}</h2>
        {faqs?.map((faq, idx) => (
          <Faq {...faq} key={`faq${idx}`} />
        ))}
      </div>
    </SectionWrapper>
  );
};
