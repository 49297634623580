import React from "react";
import * as styles from "./Cta.module.css";

interface CommonCtaProps {
  codename?: string;
  label?: string;
  large?: boolean;
  invert?: boolean;
  green?: boolean;
}

export interface LinkCtaProps extends CommonCtaProps {
  button?: false;
  href: string;
  target?: string;
  pageTitle?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

interface ButtonCtaProps extends CommonCtaProps {
  button: true;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

type CtaProps = LinkCtaProps | ButtonCtaProps;

export const Cta: React.FC<CtaProps> = (props) => {
  // using 'props' b/c typescript doesn't like
  // destructuring interdependent props ()
  const cssStyles = [styles.cta];

  if (props.large) {
    cssStyles.push(styles.large);
  }
  if (props.invert) {
    cssStyles.push(styles.invert);
  }
  if (props.green) {
    cssStyles.push(styles.green);
  }

  if (props.button) {
    return (
      <button
        type="button"
        onClick={props.onClick}
        data-kontent-element-codename={props.codename}
        className={cssStyles.join(" ")}
      >
        {props.label}
      </button>
    );
  } else {
    return (
      <a
        onClick={props.onClick}
        className={cssStyles.join(" ")}
        href={props.href}
        target={props.target}
        data-kontent-element-codename={props.codename}
      >
        {props.label}
      </a>
    );
  }
};
