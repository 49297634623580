import React from "react";
import * as styles from "./StaffMember.module.css";
import { KontentImageElement } from "../../elements/KontentImageElement/KontentImageElement";
import { Cta } from "../../elements/Cta/Cta";
import { StaffMember as StaffMemberType } from "../../../types";
import { formatImages } from "../../../lib/images";
import { KontentDataStaffMember } from "../../../types/index";

interface Props {
  member: StaffMemberType;
  showImage?: boolean;
  setContactModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContactForm: React.Dispatch<React.SetStateAction<JSX.Element>>;
}

export const parseStaffMember = (member: KontentDataStaffMember) => {
  const memberElements = member?.elements;
  const image = formatImages(memberElements?.image?.value[0]?.elements);

  return {
    firstName: memberElements?.first_name?.value,
    lastName: memberElements?.last_name?.value,
    title: memberElements?.title?.value,
    extension: memberElements?.extension?.value,
    phoneNumber: memberElements?.direct_phone_line?.value,
    emailAddress: memberElements?.email_address?.value,
    image: image.largeImage,
  };
};

export const StaffMember: React.FC<Props> = ({
  member,
  showImage,
  setContactModalOpen,
  setContactForm,
}) => {
  const handleEmailButtonClick = () => {
    setContactModalOpen(true);

    if (member.emailAddress) {
      setContactForm(contactForm);
    }
  };

  const contactForm = () => {
    return (
      <div className={styles.modalContents}>
        <h2>
          Contact {member.firstName} {member.lastName}
        </h2>
        <p>
          Please fill in your information to send an email to {member.firstName}{" "}
          {member.lastName}.
        </p>
        <iframe
          id="JotFormIFrame-221076116241040"
          title="Contact Linda Hall Library Staff"
          allow="geolocation; microphone; camera"
          src={`https://secure.jotform.com/221076116241040?staffRecipient=${member.emailAddress?.replace(
            "@",
            "%40"
          )}`}
          scrolling="no"
        ></iframe>
      </div>
    );
  };

  return (
    <div className={styles.staffMember}>
      {showImage && member?.image?.item && (
        <KontentImageElement
          image={member?.image?.item}
          alt={member?.image?.alt}
          layout="constrained"
          width={271}
          height={337}
        />
      )}
      <div className={styles.name}>
        {member?.firstName} {member?.lastName}
      </div>
      {member?.title && <div>{member?.title}</div>}
      {member?.phoneNumber && <div>{member?.phoneNumber}</div>}
      {member?.extension && <div>Ext {member?.extension}</div>}
      {member?.emailAddress && (
        <div>
          <button
            className={styles.emailButton}
            onClick={handleEmailButtonClick}
          >
            <span>Email {member?.firstName}</span>
          </button>
        </div>
      )}
    </div>
  );
};
