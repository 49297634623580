import React from "react";
import { Link as GatsbyLink } from "gatsby";

interface Props {
  to: string;
  activeClassName?: string | undefined;
  partiallyActive?: boolean | undefined;
  target?: string;
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const Link: React.FC<Props> = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  // Tailor the following test to your environment.
  // test does not contain 'http' in the to string
  const internal = !/.*http.*/.test(to?.toLowerCase());

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
        data-testid="link"
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other} data-testid="a">
      {children}
    </a>
  );
};
