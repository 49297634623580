import React from "react";
import { graphql } from "gatsby";
import { GoogleMap } from "./GoogleMap";
import { KontentComponentSettings } from "../../../types";
import { StringValue } from "../../../types/index";
import { getMultiChoice } from "../../../lib/multichoice";

interface Props extends KontentComponentSettings {
  map_code: StringValue;
}

const GoogleMapContainer: React.FC<Props> = (props) => {
  return (
    <GoogleMap
      id={props.id}
      anchor={props.component_settings__anchor_name?.value}
      hideOnMobile={
        getMultiChoice(props.component_settings__hide_on_mobile) === "yes"
      }
      mapCode={props.map_code?.value}
    />
  );
};

export default GoogleMapContainer;

export const fragmentGoogleMapContainer = graphql`
  fragment GoogleMapContainer on kontent_item_component___google_map {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      map_code {
        value
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
    }
  }
`;
