import { parseDocument, DomUtils } from "htmlparser2";

export const getTruncatedText = (html: string, length: number) => {
  let root = parseDocument(html);
  let text = DomUtils.textContent(root);
  return text.slice(0, length);
};

export const stripHtml = (html: string) => {
  let root = parseDocument(html);
  return DomUtils.textContent(root);
};
