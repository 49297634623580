import React from "react";
import { graphql } from "gatsby";
import { BlockQuote } from "./BlockQuote";
import {
  KontentComponentSettings,
  StringValue,
  KontentComponentLayout,
  KontentLinkedMediaImage,
  KontentRichText,
} from "../../../types";
import { formatImages } from "../../../lib/images";
import { getMultiChoice } from "../../../lib/multichoice";

interface Props extends KontentComponentSettings, KontentComponentLayout {
  attribution_name: StringValue;
  attribution_title: StringValue;
  image: KontentLinkedMediaImage;
  quote_content: StringValue;
  additional_byline: KontentRichText;
}

const BlockQuoteContainer: React.FC<Props> = ({
  attribution_name,
  attribution_title,
  image,
  quote_content,
  additional_byline,
  component_settings__anchor_name,
  component_settings__hide_on_mobile,
  component_layout__component_width,
  id,
}) => {
  const imageItem = formatImages(image?.value[0]?.elements);

  return (
    <BlockQuote
      attributionName={attribution_name.value}
      attributionTitle={attribution_title.value}
      quoteContent={quote_content.value}
      additionalByline={additional_byline}
      image={imageItem}
      id={id}
      width={getMultiChoice(component_layout__component_width)}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
      anchor={component_settings__anchor_name.value}
    ></BlockQuote>
  );
};

export default BlockQuoteContainer;

export const fragmentBlockQuote = graphql`
  fragment BlockQuote on kontent_item_component___block_quote {
    elements {
      attribution_name {
        value
      }
      attribution_title {
        value
      }
      component_layout__component_width {
        value {
          codename
        }
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      image {
        value {
          ...kontentMediaImage
        }
      }
      quote_content {
        value
      }
      additional_byline {
        ...kontentSimpleRichText
      }
    }
  }
`;
