import React from "react";
import { ComponentProps, Image, PageLink } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import * as styles from "./IconGroup.module.css";
import { KontentImageElement } from "../../elements/KontentImageElement/KontentImageElement";
import { Link } from "../../elements/Link/Link";

interface IconLink {
  icon: Image;
  link: PageLink;
  text: string;
  title: string;
  makeFullWidth: boolean;
}

interface Props extends ComponentProps {
  iconLinks: IconLink[];
}

export const IconGroup: React.FC<Props> = ({ iconLinks, ...props }) => {
  const renderIconLinks = () => {
    return iconLinks.map((iconLink, idx) => {
      const { title, text, icon, link, makeFullWidth } = iconLink;

      const element = (
        <div
          className={styles.iconLink}
          key={link?.href ? undefined : `iconGroup-${idx}`}
        >
          {icon && (
            <KontentImageElement
              image={icon.item}
              alt={icon.alt}
              height={makeFullWidth ? undefined : 100}
              width={makeFullWidth ? undefined : 100}
              layout="constrained"
              objectFit="contain"
              options={{ fit: "scale" }}
            />
          )}

          {title && <div className={styles.title}>{title}</div>}

          {text && <div className={styles.text}>{text}</div>}
        </div>
      );

      if (link?.href) {
        return (
          <Link to={link?.href} target={link.target} key={`iconGroup-${idx}`}>
            {element}
          </Link>
        );
      }

      return element;
    });
  };

  return (
    <SectionWrapper {...props}>
      <div className={styles.icongroup}>{renderIconLinks()}</div>
    </SectionWrapper>
  );
};
