import React, { useContext } from "react";
import * as styles from "./CustomRichTextElement.module.css";
import { RichTextElement } from "@kontent-ai/gatsby-components";
import { KontentRichText } from "../../../types/index";
import { Cta } from "../Cta/Cta";
import { formatLinks } from "../../../lib/links";
import { Element, domToReact } from "html-react-parser";
import HorizontalRuleContainer from "../../blocks/HorizontalRule";
import FormattedRichTextContainer from "../../blocks/FormattedRichText/index";
import ComponentCTAContainer from "../../blocks/ComponentCTA/index";
import { RichTextImage } from "../RichTextImage/RichTextImage";
import SidebarLinksContainer from "../../blocks/SidebarLinks/index";
import {
  StaffMember,
  parseStaffMember,
} from "../../blocks/StaffDirectory/StaffMember";
import LinkedImageContainer from "../../blocks/LinkedImage";
import BlockQuoteContainer from "../../blocks/BlockQuote";
import GoogleMapContainer from "../../blocks/GoogleMap";
import SplashEmbedContainer from "../../blocks/SplashEmbed";
import VideoPlayerContainer from "../../blocks/VideoPlayer";
import IconGroupContainer from "../../blocks/IconGroup";
import ExhibitionTimelineContainer from "../../blocks/ExhibitionTimeline";
import ExhibitionMapContainer from "../../blocks/ExhibitionMap";
import FAQsContainer from "../../blocks/FAQs";

type Link = {
  codename: string;
  url_slug: string;
  url: string;
  type: string;
  link_id: string;
};

interface KontentItemComponent {
  internal: {
    type: string;
  };
  elements: any;
  system?: {
    id: string;
    codename: string;
  };
}

interface Props {
  content: KontentRichText;
  style?: React.CSSProperties;
  classes?: string;
  anchor?: string;
}

export const CustomRichTextElement: React.FC<Props> = ({
  content,
  style,
  classes,
  anchor,
}) => {
  if (!content) {
    return null;
  }

  return (
    <div
      id={anchor}
      className={`${styles.richtextelement} ${classes}`}
      style={style}
    >
      <RichTextElement
        value={content?.value}
        images={content?.images}
        links={content?.links}
        resolveLink={(link: Link, domNode: Element) => {
          let path = link?.url;

          if (domNode?.children && path) {
            return <a href={path}>{domToReact(domNode.children)}</a>;
          }
        }}
        linkedItems={content?.modular_content}
        resolveLinkedItem={(linkedItem: KontentItemComponent) => {
          // Debug
          // return <pre>{JSON.stringify(linkedItem, undefined, 2)}</pre>

          switch (linkedItem?.internal?.type) {
            case "kontent_item_element___link":
              let cta = formatLinks([linkedItem])[0];

              return <Cta {...cta} />;

            case "kontent_item_component___horizontal_rule":
              return (
                <HorizontalRuleContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___icon_group":
              return (
                <IconGroupContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___formatted_rich_text":
              return (
                <FormattedRichTextContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___linked_image":
              return (
                <LinkedImageContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___cta":
              return (
                <ComponentCTAContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  leftAlign={true}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_media___image":
              return <RichTextImage {...linkedItem} />;

            case "kontent_item_data___staff_member":
              return <StaffMember member={parseStaffMember(linkedItem)} />;

            case "kontent_item_component___faqs":
              return (
                <FAQsContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___sidebar_links":
              return (
                <SidebarLinksContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___block_quote":
              return (
                <BlockQuoteContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___google_map":
              return (
                <GoogleMapContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___splash_embed":
              return (
                <SplashEmbedContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___video_player":
              return (
                <VideoPlayerContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem?.elements}
                />
              );

            case "kontent_item_component___exhibition_timeline":
              return (
                <ExhibitionTimelineContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem.elements}
                />
              );

            case "kontent_item_component___exhibition_map":
              return (
                <ExhibitionMapContainer
                  name={linkedItem?.internal?.type}
                  key={linkedItem?.system?.id}
                  id={linkedItem?.system?.id}
                  {...linkedItem.elements}
                />
              );

            default:
              return (
                <p>
                  <strong>{linkedItem?.internal?.type}</strong> component not
                  available in rich text element. To correct, add component to{" "}
                  <code>CustomRichTextElement</code> in
                  <code>src/components/elements/CustomRichTextElement</code>.
                </p>
              );
          }
        }}
      />
    </div>
  );
};
