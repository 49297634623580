import React from "react";
import { ComponentProps, KontentRichText, PageLink } from "../../../types";
import { RichTextImage } from "../../elements/RichTextImage/RichTextImage";

interface Props extends ComponentProps {
  link: PageLink;
  image: KontentRichText;
}

export const LinkedImage: React.FC<Props> = ({ link, image }) => {
  if (
    !image?.modular_content ||
    image?.modular_content[0]?.system.type !== "media___image"
  )
    return null;

  return (
    <>
      <RichTextImage {...image.modular_content[0]} link={link}></RichTextImage>
    </>
  );
};
