import React from "react";
import * as styles from "./CloseButton.module.css";

interface Props {
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  size?: number;
}

const CloseButton: React.FC<Props> = ({
  color = "#000",
  size = "20",
  onClick,
}) => {
  return (
    <button className={styles.closeButton} onClick={onClick} aria-label="Close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16.66 16.66"
        width={size}
        height={size}
      >
        <defs />
        <g id="Layer_2" data-name="Layer 2">
          <g id="artwork">
            <path
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M.5.5l15.66 15.66M.5 16.16L16.16.5"
            />
          </g>
        </g>
      </svg>
    </button>
  );
};

export default CloseButton;
