import React from "react";
import * as styles from "./TimelineDetail.module.css";
import { KontentTimelineDetail } from ".";
import { formatImages } from "../../../lib/images";
import { KontentImageElement } from "../../elements/KontentImageElement/KontentImageElement";
import { getMultiChoice } from "../../../lib/multichoice";
import { CustomRichTextElement } from "../../elements/CustomRichTextElement/CustomRichTextElement";

interface Props extends KontentTimelineDetail {}

export const TimelineDetail: React.FC<Props> = ({
  system,
  elements,
  ...props
}) => {
  const {
    header_image,
    header_title,
    header_title_location,
    header_title_color,
    detail_heading,
    detail_subhead,
    text,
  } = elements;
  const size = getMultiChoice(elements.size);
  const detailClasses = [styles.timelinedetail];
  size === "large" && detailClasses.push(styles.large);
  size === "small" && detailClasses.push(styles.small);

  const headerImage = formatImages(header_image?.value[0]?.elements);
  const titleClasses = [styles.header];
  getMultiChoice(header_title_location) === "low" &&
    titleClasses.push(styles.low);
  getMultiChoice(header_title_color) === "green" &&
    titleClasses.push(styles.green);

  return (
    <div className={detailClasses.join(" ")}>
      <div className={styles.header}>
        {headerImage?.largeImage?.item && (
          <KontentImageElement
            image={headerImage?.largeImage.item}
            alt={headerImage?.largeImage.alt}
            className={styles.image}
          />
        )}
        {header_title?.value && (
          <div className={titleClasses.join(" ")}>
            {header_title?.value}
            <div className={styles.arrow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20.93}
                height={29.576}
              >
                <path
                  fill="#00c18a"
                  d="M10.465 29.574 0 0h20.93Z"
                  data-name="Path 11"
                />
              </svg>
            </div>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.heading}>{detail_heading.value}</div>
        <div className={styles.subhead}>{detail_subhead.value}</div>
        <CustomRichTextElement content={text} />
      </div>
    </div>
  );
};
