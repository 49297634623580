import React from "react";
import { graphql } from "gatsby";
import { SidebarLinks } from "./SidebarLinks";
import { KontentLinks, KontentLink } from "../../../types";

export interface KontentLinkWithSublinks {
  id: string;
  internal?: {
    type?: string;
  };
  elements: {
    primary_link: KontentLinks[];
    sublinks: KontentLinks[];
  };
}

interface Props {
  link_configuration__link: KontentLinks;
  links: {
    value: string;
    modular_content: (KontentLink | KontentLinkWithSublinks)[];
  };
}

const SidebarLinksContainer: React.FC<Props> = (props) => {
  return <SidebarLinks links={props.links} />;
};

export default SidebarLinksContainer;

export const fragmentSidebarLinksContainer = graphql`
  fragment SidebarLinks on kontent_item_component___sidebar_links {
    system {
      id
      codename
    }
    internal {
      type
    }
    elements {
      links {
        value
        modular_content {
          system {
            id
            codename
          }
          internal {
            type
          }
          ...kontentRichTextLinkWithSublink
          ...kontentRichTextLink
        }
      }
    }
  }
`;
