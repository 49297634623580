import { KontentMediaImage, Image, KontentMediaIcon } from "../types";
import { stripHtml } from "./descriptions";

export function formatImages(mediaImage: KontentMediaImage["elements"]): {
  largeImage: Image;
  smallImage: Image;
} {
  return {
    largeImage: {
      item: mediaImage?.image?.value[0],
      alt:
        mediaImage?.alt_text?.value ||
        stripHtml(mediaImage?.image?.value[0]?.description),
      caption: mediaImage?.caption,
    },
    smallImage: {
      item: mediaImage?.small_image?.value[0],
      alt:
        mediaImage?.alt_text?.value ||
        stripHtml(mediaImage?.small_image?.value[0]?.description),
      caption: mediaImage?.caption,
    },
  };
}

export function formatIcon(mediaIcon: KontentMediaIcon["elements"]): Image {
  return {
    item: mediaIcon?.icon?.value[0],
    alt: mediaIcon?.alt_text?.value,
  };
}
